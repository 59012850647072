import React from "react"
import SettingsCard from "../../../atoms/settings-card"
import FeatureToggle from "../../../fundamentals/feature-toggle"
import ChannelsIcon from "../../../fundamentals/icons/channels-icon"
import CoinsIcon from "../../../fundamentals/icons/coins-icon"
import CrosshairIcon from "../../../fundamentals/icons/crosshair-icon"
import DollarSignIcon from "../../../fundamentals/icons/dollar-sign-icon"
import HappyIcon from "../../../fundamentals/icons/happy-icon"
import MailIcon from "../../../fundamentals/icons/mail-icon"
import MapPinIcon from "../../../fundamentals/icons/map-pin-icon"
import TaxesIcon from "../../../fundamentals/icons/taxes-icon"
import TruckIcon from "../../../fundamentals/icons/truck-icon"
import UsersIcon from "../../../fundamentals/icons/users-icon"
import SettingsOverview from "../../../templates/settings-overview"

const Settings = () => {
  return (
    <SettingsOverview>
      <SettingsCard
        heading={"Regions"}
        description={"Manage the markets you will operate within"}
        icon={<MapPinIcon />}
        to={`/retail/settings/regions`}
      />
      <SettingsCard
        heading={"Currencies"}
        description={"Manage the markets you will operate within"}
        icon={<CoinsIcon />}
        to={`/retail/settings/currencies`}
      />
      <SettingsCard
        heading={"Store Details"}
        description={"Manage your business details"}
        icon={<CrosshairIcon />}
        to={`/retail/settings/details`}
      />
      <SettingsCard
        heading={"Shipping"}
        description={"Manage shipping profiles"}
        icon={<TruckIcon />}
        to={`/retail/settings/shipping-profiles`}
      />
      <SettingsCard
        heading={"Payments"}
        description={"Manage payment methods"}
        icon={<TruckIcon />}
        to={`/retail/settings/payments`}
      />
      <SettingsCard
        heading={"Return Reasons"}
        description={"Manage Order settings"}
        icon={<DollarSignIcon />}
        to={`/retail/settings/return-reasons`}
      />
      <SettingsCard
        heading={"The Team"}
        description={"Manage users of your Monmi Store"}
        icon={<UsersIcon />}
        to={`/retail/settings/team`}
      />
      <SettingsCard
        heading={"Personal Information"}
        description={"Manage your Monmi profile"}
        icon={<HappyIcon />}
        to={`/retail/settings/personal-information`}
      />
      <SettingsCard
        heading={"hello@monmi.vn"}
        description={"Can’t find the answers you’re looking for?"}
        icon={<MailIcon />}
        externalLink={"mailto: hello@monmi.vn"}
      />
      <SettingsCard
        heading={"Tax Settings"}
        description={"Manage taxes across regions and products"}
        icon={<TaxesIcon />}
        to={`/retail/settings/taxes`}
      />
      <FeatureToggle featureFlag="sales_channels">
        <SettingsCard
          heading={"Sales channels"}
          description={"Control which products are available in which channels"}
          icon={<ChannelsIcon />}
          to={`/retail/settings/sales-channels`}
        />
      </FeatureToggle>
    </SettingsOverview>
  )
}

export default Settings
